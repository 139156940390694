
import { Component, Prop, Vue } from 'vue-property-decorator';
import DttTypo from '@/components/Typo/index.vue';
import DttIcon from '@/components/Icon/index.vue';
import { IconName } from '@/components/Icon/types';
import { StatusSize, StatusType } from '@/components/Status/types';

@Component({
  name: 'DttStatus',
  components: { DttIcon, DttTypo },
})
export default class Status extends Vue {
  @Prop({ type: String, default: 'neutral' }) type!: StatusType;
  @Prop({ type: String, default: 'md' }) size!: StatusSize;
  @Prop({ type: String }) icon?: IconName;

  get textSize() {
    switch (this.size) {
      case 'sm':
        return 'xs';
      default:
        return this.size;
    }
  }

  get iconSize() {
    switch (this.size) {
      case 'lg':
        return '24px';
      case 'md':
        return '20px';
      case 'sm':
        return '16px';
      case 'xs':
        return '16px';
      default:
        return this.size;
    }
  }

  get classes() {
    return [`dtt-status--${this.type}`, `dtt-status--${this.size}`];
  }
}
