
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import { getElementViewportArea, ViewportArea } from '@/utils/screen';
import DttTypo from '@/components/Typo/index.vue';
import DttIcon from '@/components/Icon/index.vue';

type DatePickerMode = 'year' | 'month' | 'date';
type Month = {
  label: string;
  date: Date;
};

@Component({
  name: 'DttDatePicker',
  components: { DttIcon, DttTypo },
})
export default class DatePicker extends Vue {
  @ModelSync('value', 'change', { type: Date, default: () => new Date() })
  modelValue!: Date | null;
  @Prop({ type: String, default: 'date' }) fallbackMode!: DatePickerMode;
  @Prop({ type: Date }) disabledBefore?: Date;
  @Prop({ type: Date }) disabledAfter?: Date;

  previewValue: Date = new Date();
  isOpen = false;
  area?: ViewportArea;
  mode: DatePickerMode = 'date';

  get previewDate() {
    return {
      day: this.previewValue.getDate(),
      month: this.previewValue.getMonth(),
      year: this.previewValue.getFullYear(),
    };
  }

  get weeks() {
    return ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
  }

  get months() {
    return [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ];
  }

  get years() {
    const rows: Date[][] = [];
    const { year, month, day } = this.previewDate;

    for (let i = year - 4; i <= year + 4; i++) {
      if ((i - (year - 4)) % 3 === 0) rows.push([]);
      rows[rows.length - 1].push(new Date(i, month, day));
    }

    return rows;
  }

  get monthsInYear() {
    const rows: Month[][] = [];
    const { year } = this.previewDate;

    this.months.forEach((month, index) => {
      if (index % 3 === 0) {
        rows.push([]);
      }
      rows[rows.length - 1].push({
        label: month,
        date: new Date(year, index, 1),
      });
    });

    return rows;
  }

  get daysInWeeks() {
    const { year, month } = this.previewDate;
    const weeks: (Date | null)[][] = [[]];
    let lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    let firstWeekDayOfMonth = new Date(year, month, 1).getDay() - 1;
    firstWeekDayOfMonth = firstWeekDayOfMonth === -1 ? 6 : firstWeekDayOfMonth;
    let weekIndex = 0;

    for (let i = -firstWeekDayOfMonth; i < 0; i++) {
      weeks[weekIndex].push(null);
    }

    for (let i = 0; i < lastDayOfMonth; i++) {
      if ((i + firstWeekDayOfMonth) % 7 === 0) {
        weekIndex++;
        weeks.push([]);
      }
      weeks[weekIndex].push(new Date(year, month, i + 1));
    }

    return weeks;
  }

  get viewportAreaClass() {
    return `dtt-date-picker__panel--${this.area}`;
  }

  isActiveDate(date: Date | null) {
    if (!date || !this.modelValue) return false;
    const year = this.modelValue.getFullYear();
    const month = this.modelValue.getMonth();
    const day = this.modelValue.getDate();
    switch (this.mode) {
      case 'date':
        return (
          date.getDate() === day &&
          date.getMonth() === month &&
          date.getFullYear() === year
        );
      case 'month':
        return date.getMonth() === month && date.getFullYear() === year;
      case 'year':
        return date.getFullYear() === year;
    }
  }

  isDisabledDate(date: Date | null) {
    if (!date) return true;
    switch (this.mode) {
      case 'date':
        return (
          (this.disabledBefore && date < this.disabledBefore) ||
          (this.disabledAfter && date > this.disabledAfter)
        );
      case 'month':
        return (
          (this.disabledBefore &&
            date.getMonth() < this.disabledBefore.getMonth() &&
            date.getFullYear() < this.disabledBefore.getFullYear()) ||
          (this.disabledAfter &&
            date.getMonth() > this.disabledAfter.getMonth() &&
            date.getFullYear() > this.disabledAfter.getFullYear())
        );
      case 'year':
        return (
          (this.disabledBefore &&
            date.getFullYear() < this.disabledBefore.getFullYear()) ||
          (this.disabledAfter &&
            date.getFullYear() > this.disabledAfter.getFullYear())
        );
    }
  }

  toggle() {
    if (!this.isOpen) {
      this.mode = this.fallbackMode;
      this.previewValue = this.modelValue || new Date();
    }
    this.area = getElementViewportArea(this.$refs.target as Element);
    this.isOpen = !this.isOpen;
  }

  toggleMode(mode: DatePickerMode) {
    if (this.mode === mode) this.mode = this.fallbackMode;
    else this.mode = mode;
  }

  selectDate(date: Date | null) {
    if (!date || this.isDisabledDate(date)) return;
    this.previewValue = date;
    this.modelValue = date;
    if (this.mode === this.fallbackMode) {
      this.isOpen = false;
    } else {
      this.mode = this.fallbackMode;
    }
  }

  nextMonthHandler() {
    const { year, month, day } = this.previewDate;

    if (this.mode === 'date')
      this.previewValue = new Date(year, month + 1, day);
    else if (this.mode === 'month')
      this.previewValue = new Date(year + 1, month, day);
    else if (this.mode === 'year') {
      this.previewValue = new Date(year + 8, month, day);
    }
  }

  prevMonthHandler() {
    const { year, month, day } = this.previewDate;

    if (this.mode === 'date')
      this.previewValue = new Date(year, month - 1, day);
    else if (this.mode === 'month')
      this.previewValue = new Date(year - 1, month, day);
    else if (this.mode === 'year') {
      this.previewValue = new Date(year - 8, month, day);
    }
  }

  outsideClickHandler(e: PointerEvent) {
    const el = this.$refs.el as Element;
    if (!el.contains(e.target as Node)) {
      this.isOpen = false;
    }
  }

  beforeMount() {
    this.previewValue = this.modelValue || new Date();
  }

  mounted() {
    //@ts-ignore
    document.addEventListener('click', this.outsideClickHandler);
  }

  beforeDestroy() {
    //@ts-ignore
    document.removeEventListener('click', this.outsideClickHandler);
  }
}
