import type { PluginObject, VueConstructor } from 'vue';
import * as components from './components';

const componentsList = components.default;
const install = (vue: VueConstructor) => {
  Object.values(componentsList).forEach((component) => {
    //@ts-ignore
    vue.component(component?.extendOptions?.name, component);
  });
};

export default {
  install,
  ...componentsList,
} as PluginObject<unknown>;

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}
