
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import DttIcon from '@/components/Icon/index.vue';
import { IconName } from '@/components/Icon/types';
import { InputSize } from '@/components/Input/types';

@Component({
  name: 'DttInput',
  components: { DttIcon },
})
export default class Input extends Vue {
  @ModelSync('value', 'change') modelValue?: string | number | null;
  @Prop({ type: String, required: true, default: '' }) name!: string;
  @Prop({ type: String, default: 'text' }) type!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) resizable!: boolean;
  @Prop({ type: Number, default: 1 }) textAreaRows!: string;
  @Prop({ type: Boolean, default: false }) clearable!: boolean;
  @Prop({ type: [Boolean, String], default: null }) validation!:
    | boolean
    | string
    | null;
  @Prop({ type: String, default: '' }) placeholder!: string;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: '' }) help!: string;
  @Prop({ type: String, default: 'md' }) size!: InputSize;
  @Prop({ type: String, default: '' }) iconLeft!: IconName;
  @Prop({ type: String, default: '' }) iconRight!: IconName;
  @Prop({ type: Boolean, default: false }) isDirtyInitial!: boolean;
  @Prop({ type: String, default: 'dirty' })
  validationMode!: string;
  @Prop({ default: undefined }) trueValue: any;

  isDirty = false;

  get hasValue() {
    return (
      this.modelValue !== '' &&
      this.modelValue !== null &&
      this.modelValue !== undefined
    );
  }
  get iconSize() {
    switch (this.size) {
      case 'sm':
        return '20px';
      default:
        return '24px';
    }
  }

  get hasErrorState() {
    return (
      (this.validation === false || typeof this.validation === 'string') &&
      this.validationMode === 'dirty' &&
      this.isDirty
    );
  }

  get hasSuccessState() {
    return (
      this.validation === this.trueValue &&
      this.validationMode === 'dirty' &&
      this.isDirty
    );
  }

  get inputClasses() {
    return [this.hasErrorState && 'error', this.hasSuccessState && 'success'];
  }

  clearClick() {
    this.modelValue = '';
  }

  beforeMount() {
    this.isDirty = this.isDirtyInitial;
  }
}
