var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dtt-table-wrapper",style:(_vm.wrapperStyles)},[_c('table',{staticClass:"dtt-table"},[_c('colgroup',[(_vm.selectable)?_c('col',{style:(`width: ${_vm.selectColumnSize}`)}):_vm._e(),_vm._l((_vm.columnsData),function(columnData,index){return _c('col',_vm._b({key:index},'col',{ ...columnData.props.colProp },false))})],2),_c('thead',{staticClass:"dtt-table__header"},[_c('tr',{directives:[{name:"show",rawName:"v-show",value:(!_vm.noHeader),expression:"!noHeader"}],staticClass:"dtt-table__header-row"},[(_vm.selectable)?_c('th',{staticClass:"dtt-table__header-cell",class:['divider', _vm.headerSize]},[_c('div',{staticClass:"dtt-table__header-cell-inner checkbox"},[_c('DttCheckbox',{attrs:{"value":_vm.allRowsSelected,"size":_vm.selectSize},on:{"change":_vm.toggleAllSelections}})],1)]):_vm._e(),_vm._l((_vm.columnsData),function(columnData,index){return _c('th',{key:index,staticClass:"dtt-table__header-cell",class:[
            columnData.props.thProps.divider && 'divider',
            columnData.props.thProps.align,
            _vm.headerSize,
            columnData.props.thProps.class,
          ]},[_c('div',{staticClass:"dtt-table__header-cell-inner",class:{
              sortable: columnData.props.sortable,
            },on:{"click":function($event){columnData.props.sortable && _vm.handleSort(columnData.props.prop)}}},[(columnData.props.sortable)?_c('DttIcon',{staticClass:"dtt-table__header-icon-left dtt-table__header-icon-sort",class:[_vm.getSortDirection(columnData.props.prop)],attrs:{"name":"decrease","color":_vm.getSortColor(columnData.props.prop),"width":_vm.iconSize,"height":_vm.iconSize}}):_vm._e(),(columnData.headerRenderer)?_c('VNodeRenderer',{attrs:{"items":columnData.headerRenderer(columnData.props)}}):[_vm._v(" "+_vm._s(columnData.props.header)+" ")]],2)])})],2)]),_c('tbody',{staticClass:"dtt-table__body"},[(_vm.data?.length)?[(_vm.hasSomeSummary)?_c('tr',{staticClass:"dtt-table__body-row",class:[_vm.hoverable && 'hoverable']},[_c('td',{staticClass:"dtt-table__body-cell summary-label"},[_vm._v("ИТОГО")]),_vm._l((_vm.columnsData),function(columnData,columnIndex){return _c('td',{key:columnIndex,staticClass:"dtt-table__body-cell summary-text",class:[
              columnData.props.tdProps.align,
              _vm.cellSize,
              _vm.hoverable,
              columnData.props.tdProps.class,
            ]},[(columnData.summaryRenderer)?_c('VNodeRenderer',{attrs:{"items":columnData.summaryRenderer({
                  props: columnData.props,
                })}}):_vm._e()],1)})],2):_vm._e(),_vm._l((_vm.data),function(dataItem,dataIndex){return _c('tr',{key:dataIndex,staticClass:"dtt-table__body-row",class:[_vm.hoverable && 'hoverable'],on:{"click":function($event){return _vm.rowClick(dataItem)}}},[(_vm.selectable)?_c('td',{staticClass:"dtt-table__body-cell checkbox",class:['divider', _vm.cellSize, _vm.hoverable],on:{"click":function($event){$event.stopPropagation();}}},[_c('DttCheckbox',{attrs:{"value":_vm.isSelectedRow(dataItem),"size":_vm.selectSize},on:{"change":function($event){return _vm.toggleRowSelection(dataItem)}}})],1):_vm._e(),_vm._l((_vm.columnsData),function(columnData,columnIndex){return _c('td',{key:columnIndex,staticClass:"dtt-table__body-cell",class:[
              columnData.props.tdProps.divider && 'divider',
              columnData.props.tdProps.align,
              _vm.cellSize,
              _vm.hoverable,
              columnData.props.tdProps.class,
            ]},[(columnData.bodyRenderer)?_c('VNodeRenderer',{attrs:{"items":columnData.bodyRenderer({
                  props: columnData.props,
                  dataItem: dataItem,
                })}}):[_vm._v(" "+_vm._s(dataItem[columnData.props.prop])+" ")]],2)})],2)})]:[_vm._m(0)]],2)]),_c('div',[_vm._t("default")],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"dtt-table__body-row"},[_c('td',{staticClass:"dtt-table__body-cell empty",attrs:{"colspan":"100"}},[_vm._v(" Данные не найдены ")])])
}]

export { render, staticRenderFns }