
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IconName } from '@/components/Icon/types';
import DttIcon from '@/components/Icon/index.vue';

interface AsideItem {
  label: string;
  to: string;
  icon: IconName;
}

@Component({
  name: 'DttLayout',
  components: { DttIcon },
})
export default class Layout extends Vue {
  @Prop({ type: Array, default: () => [] }) asideItems!: AsideItem[];
  @Prop({ type: String }) settingsButton?: string;

  isOpen = false;

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
